import React from 'react';
import PL from '../../../../icons/papabonus/pl.svg';
import classNames from 'classnames/bind';

//Styles
import styles from './Content.styl';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentLoyaltySelector, daysResetStatus } from '../../../../state/modules/user/selectors';
import { Link } from 'react-router-dom';
import { getRoute, paths } from '../../../../entry/routes';

const cx = classNames.bind(styles);

const Content = () => {
  const { isPhone } = useSelector(state => state.responsive);
  const intl = useIntl();
  const currentLoyaltyName = useSelector(currentLoyaltySelector)?.name;
  const daysResetPL = useSelector(daysResetStatus);

  const mobileStyle = isPhone ? cx('ModalContainer__greenText') : null;

  return (
    <>
      <section className={cx('ModalContainer__icon')}>
        <PL width={150} height={150} />
      </section>
      <section className={cx('ModalContainer__header')}>
        {intl.formatMessage(
          { id: 'loyalty.tooltip.header' }
        )}
      </section>
      <section className={cx('ModalContainer__text')}>
        {intl.formatMessage({ id: 'loyalty.tooltip.text1' })}
        <span className={mobileStyle}>
          {`"${currentLoyaltyName}"`}
        </span>
        {intl.formatMessage({ id: 'loyalty.tooltip.text2' })}
        <span className={mobileStyle}>
          {daysResetPL}
          {intl.formatMessage({ id: 'loyalty.tooltip.text3' })}
        </span>
        {intl.formatMessage({ id: 'loyalty.tooltip.text34' })}
      </section>
      <section className={cx('ModalContainer__text')}>
        {intl.formatMessage({ id: 'loyalty.tooltip.text4' })}
        <span className={mobileStyle}>
          {intl.formatMessage({ id: 'loyalty.tooltip.text5' })} </span>
        {intl.formatMessage({ id: 'loyalty.tooltip.text6' })}
      </section>
      <section>
        <Link to={getRoute(paths.papabonus)} className={cx('ModalContainer__link')}>
          {intl.formatMessage(
            { id: 'papabonus.details.link2' }
          )}
        </Link>
      </section>

    </>
  );
};

export default Content;
